<template>
  <home-blocks-wrapper
    id="courses-popular"
    :title="LL.course_title()"
    :highlight-title="LL.popular_title()"
    link-view-more="/khoa-hoc"
  >
    <div
      :class="[
        'grid gap-6 mt-12',
        userStore.isVietnamMarket ? 'grid-cols-12' : 'grid-cols-3',
      ]"
    >
      <template v-for="c in courses" :key="c.id">
        <CoursePopularItemVue
          :background="c.background"
          :title="c.title"
          :description="c.description"
          :image-url="c.imageUrl"
          :redirect-link="c.redirectLink"
        />
      </template>
    </div>
  </home-blocks-wrapper>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/user";
import CoursePopularItemVue from "./CoursePopularItem.vue";
import { typesafeI18n } from "~/locales/i18n-vue";
const { LL } = typesafeI18n();

const userStore = useUserStore();

const courses = computed(() => {
  if (userStore.isGlobalMarket) {
    return [
      {
        id: "algorithms",
        title: LL.value.course_title_basic_advanced({ course: "Algorithms" }),
        description:
          "Algorithms Course is a comprehensive program delving into the core principles of algorithms, focusing on problem-solving techniques, data structures, and algorithm analysis. Through lectures, assignments, and hands-on coding exercises, students grasp fundamental algorithms' intricacies, enhancing their problem-solving skills and algorithmic thinking",
        imageUrl: "/images/home/blocks/CourseAlgorithms.png",
        redirectLink: "/khoa-hoc/java",
        background: "bg-cyan-50 dark:bg-cyan-900",
      },
    ];
  } else if (userStore.isVietnamMarket) {
    return [
      {
        id: "java",
        title: LL.value.course_title_basic_advanced({ course: "Java" }),
        description: LL.value.java_course_description(),
        imageUrl: "/images/home/blocks/CourseJava.png",
        redirectLink: "/khoa-hoc/java",
        background: "bg-amber-50 dark:bg-gray-900",
      },
      {
        id: "javascript",
        title: LL.value.course_title_basic_advanced({ course: "Javascript" }),
        description: LL.value.javascript_course_description(),
        imageUrl: "/images/home/blocks/CourseJavascript.png",
        redirectLink: "/khoa-hoc/javascript",
        background: "bg-amber-50 dark:bg-gray-900",
      },
      {
        id: "react",
        title: LL.value.course_title_basic_advanced({ course: "Reactjs" }),
        description: LL.value.react_course_description(),
        imageUrl: "/images/home/blocks/CourseReact.png",
        redirectLink: "/khoa-hoc/reactjs",
        background: "bg-cyan-50 dark:bg-gray-900",
      },
      {
        id: "c++",
        title: LL.value.course_title_basic_advanced({ course: "C++" }),
        description: LL.value.c_plus_course_description(),
        imageUrl: "/images/home/blocks/CourseC.png",
        redirectLink: "/khoa-hoc/cpp",
        background: "bg-fuchsia-100 dark:bg-gray-900",
      },
    ];
  } else
    return [
      {
        id: "java",
        title: LL.value.course_title_basic_advanced({ course: "SE" }),
        description: 'Chìa khoá để thành công',
        imageUrl: "/images/home/blocks/CourseSE.png",
        redirectLink: "/khoa-hoc/java",
        background: "bg-cyan-50 dark:bg-cyan-900",
      },
    ];
});
</script>
